import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import NoRowsOverlay from '@/components/atoms/Icon/NoRowsOverlay'
import { energySavingReportApi } from '@/ghgApi'
import { EnergySavingLevel, EnergySavingOriginateCreate, EnergySavingOriginateCreateArr, EnergySavingReport } from '@/openapi'
import { TEnergySavingTable } from '@/pages/reporting/energy-saving'
import theme from '@/theme'
import { filterByUniqueId } from '@/utils/arrayHelper'
import { convertEnergySavingToCsv } from '@/utils/csvHelper'
import { sumOfMatrix } from '@/utils/math'
import { checkMessageText } from '@/utils/messageHelper'
import { getDescendantsSiteIds, getHierarchyLevel, getParentSites } from '@/utils/siteNodeHelper'
import useStore from '@/zustand/sotre'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { useMemo, useState, SetStateAction, useEffect } from 'react'
import EnergySavingTableRow from './TableRow/EnergySavingTableRow'

interface EnergySavingTable {
    data: TEnergySavingTable
    setCsvData: React.Dispatch<SetStateAction<Blob | undefined>>
    selectedYear: number
    onReFetch: () => void
}
interface EnergySavingLevelWithUnit extends EnergySavingLevel {
    units?: Unit[]
}
export interface EnergySavingRow {
    siteId: number | undefined
    title: string | undefined
    level: number
    items: EnergySavingRowItem[]
}
interface EnergySavingRowItem {
    energySavingId?: number | undefined
    valueType?: ValueType | undefined
    value: number
    unit?: string | undefined
    inputtable: boolean
}
type ValueType =
    | 'activityQuantity'
    | 'emissionNumber'
    | 'heatQuantity'
    | 'total'

const useStyle = makeStyles({
    btnSaveData: {
        position: 'fixed',
        backgroundColor: theme.colors.white,
        textAlign: 'end',
        right: 22,
        zIndex: 1,
        width: '100%',
        paddingBottom: 16,
    },
    container: {
        padding: 10,
        border: `1px solid ${theme.colors.border}`,
        position: 'relative',
        '& .vertical-line': {
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: '#fff',
            width: 160,
            height: 'calc(100% - 58px)',
        },
        '& .desc': {
            marginTop: 10,
            textAlign: 'right',
            fontWeight: 300,
        },
    },
    tableContainer: {},
    stickyCol: {
        position: 'sticky',
        left: 1,
    },
    cell: { minWidth: 100, fontSize: 14, padding: 9 },
    headerCell: {
        fontWeight: 600,
        color: '#fff',
        backgroundColor: '#6E6E6E',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    unitCell: { backgroundColor: '#AAAAAA' },
    bodyCell: { color: '#222222', fontWeight: 300 },
    bodySiteCell: {
        backgroundColor: '#FDF3DF',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    bodyTotalCell: { fontWeight: 700, backgroundColor: '#F5DBAE' },
    inputCol: {
        padding: '8px 0px 8px 16px',
    },
    input: {
        width: 135,
        marginLeft: 7,
        '& fieldset': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
        },
        '& input': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
            textAlign: 'right',
        },
    },
})
type Unit = { name: string[]; id?: number }
const genUnits = (isCsvDownload?: boolean, id?: number, unit: string = 'kl'): Unit[] => {
    const result = [
        { name: ['①活動量', `（${unit}）`], id },
        { name: ['②排出量', '（t-CO₂e）'], id },
        { name: ['③熱量', '（GJ）'], id },
    ]
    if (isCsvDownload) {
        result.push({ name: ['④係数'], id })
    }
    return result
}

const genSubTotalUnits = (): Unit[] => {
    const result = [
        { name: ['集計①', ''] },
        { name: ['集計②', ''] },
        { name: ['集計③', ''] },
        { name: ['集計④', ''] },
        { name: ['集計⑤', ''] },
    ]
    return result
}

function EnergySavingTable({ data: { headers, filteredData: data }, ...props }: EnergySavingTable) {
    const classes = useStyle()
    const {
        setMessage,
        storeState: { sites },
    } = useStore()
    const [originalRows, setOriginalRows] = useState<EnergySavingRow[]>([])
    const [rows, setRows] = useState<EnergySavingRow[]>([])
    const [tableCol, setTableCol] = useState<Unit[]>([])
    const [tableColCSV, setTableColCSV] = useState<Unit[]>([])
    const [disabledSaveButton, setDisabledSaveButton] = useState(true)

    useEffect(() => {
        const rows = initializeTableData()
        setOriginalRows(JSON.parse(JSON.stringify(rows)))
        setRows(rows)
        setDisabledSaveButton(true)
        prepareEnergySavingCsv()
    }, [data])

    function getLastLevelChildren(obj: EnergySavingLevel) {
        let lastLevelChildren: EnergySavingLevel[] = []
        if (obj.children) {
            obj.children.forEach((child) => {
                if (child.children) {
                    lastLevelChildren = lastLevelChildren.concat(child.children)
                } else {
                    lastLevelChildren.push(child)
                }
            })
        }
        return lastLevelChildren
    }

    const tableHeader = useMemo(() => {
        const colUnitsDownload: Unit[][] = []
        const newHeaders = headers.map((h) => {
            const children = getLastLevelChildren(h)
            const colUnits = children
                .map((child) => {
                    const result = genUnits(false, child.id, child.gj_unit)
                    const resultForDownload = genUnits(true, child.id, child.gj_unit)
                    colUnitsDownload.push(resultForDownload)
                    return result
                })
                .flat()
            setTableCol((pre) => pre.concat(colUnits))
            setTableColCSV(colUnitsDownload.flat())
            return { ...h, units: colUnits }
        })
        return [
            { name: '拠点名' },
            { name: '階層単位集計 (GJ)', units: genSubTotalUnits() },
            ...newHeaders,
        ] as EnergySavingLevelWithUnit[]
    }, [headers])

    const initializeTableData = (isCsvDownload: boolean = false): EnergySavingRow[] => {
        const rows = data.map((reportRow: EnergySavingReport) => {
            const level = getHierarchyLevel(sites, reportRow.site_id as number)
            const uniqueTableCol = filterByUniqueId(tableCol)
            const items = uniqueTableCol.map((col, _) => {
                const rowData = reportRow.data?.find((data) => data.energy_saving_id === col.id)
                const activity: EnergySavingRowItem = (rowData?.activity || rowData?.inputtable)
                    ? {
                        energySavingId: rowData.energy_saving_id,
                        valueType: 'activityQuantity',
                        value: rowData.activity ?? 0,
                        unit: rowData.unit,
                        inputtable: rowData.inputtable ?? false,
                    } : {
                        value: 0,
                        inputtable: false
                    }
                const emissionNumber: EnergySavingRowItem = (rowData?.emission_number || rowData?.inputtable)
                    ? {
                        energySavingId: rowData.energy_saving_id,
                        valueType: 'emissionNumber',
                        value: rowData.emission_number ?? 0,
                        unit: 't-CO2e',
                        inputtable: rowData.inputtable ?? false,
                    } : {
                        value: 0,
                        inputtable: false
                    }
                const amountHeat: EnergySavingRowItem = (rowData?.amount_heat || rowData?.inputtable)
                    ? {
                        energySavingId: rowData.energy_saving_id,
                        valueType: 'heatQuantity',
                        value: rowData.amount_heat ?? 0,
                        unit: 'gj',
                        inputtable: rowData.inputtable ?? false,
                    } : {
                        value: 0,
                        inputtable: false
                    }
                const item = [
                    activity,
                    emissionNumber,
                    amountHeat,
                ]
                const coefficient: EnergySavingRowItem | undefined = isCsvDownload
                    ? rowData?.coefficient
                        ? {
                            energySavingId: rowData.energy_saving_id,
                            value: rowData.coefficient,
                            inputtable: rowData.inputtable ?? false,
                        } : {
                            value: 0,
                            inputtable: false
                        }
                    : undefined
                if (coefficient) {
                    item.push(coefficient)
                }
                return item
            })
            return {
                siteId: reportRow.site_id,
                title: reportRow.site_name,
                level: level,
                items: [
                    { energySavingId: -1, value: 0, inputtable: false },
                    { energySavingId: -2, value: 0, inputtable: false },
                    { energySavingId: -3, value: 0, inputtable: false },
                    { energySavingId: -4, value: 0, inputtable: false },
                    { energySavingId: -5, value: 0, inputtable: false },
                    ...items.flat(),
                ],
            }
        })
        return calculateSubTotals(rows)
    }

    const calculateSubTotals = (rows: EnergySavingRow[]) => {
        const calculatedRows = rows.map((row) => {
            let subTotalGj = 0
            // 小計を除外
            const filteredItems = row.items.filter((item) => (item.energySavingId && item.energySavingId > 0) || (!item.energySavingId))
            filteredItems.forEach((item) => {
                if (item.unit === 'gj') {
                    subTotalGj += item.value
                }
            })
            const level = getHierarchyLevel(sites, row.siteId as number)
            const subTotalItems = [
                { energySavingId: -1, value: level === 1 ? subTotalGj : 0, inputtable: false },
                { energySavingId: -2, value: level === 2 ? subTotalGj : 0, inputtable: false },
                { energySavingId: -3, value: level === 3 ? subTotalGj : 0, inputtable: false },
                { energySavingId: -4, value: level === 4 ? subTotalGj : 0, inputtable: false },
                { energySavingId: -5, value: level === 5 ? subTotalGj : 0, inputtable: false },
            ]
            return {
                ...row,
                items: [
                    ...subTotalItems,
                    ...row.items.filter((_, index) => index >= subTotalItems.length),
                ]
            }
        })
        const reCalculatedRows = calculatedRows.map((row) => {
            // 各拠点ごとに、その子孫拠点すべてのsiteIdリストを取得する
            const descendantsSiteIds = getDescendantsSiteIds(row.siteId as number, sites)
            const descendantsSiteRows = calculatedRows.filter((innerRow) => {
                return descendantsSiteIds.some((siteId) => siteId == innerRow.siteId)
            })
            // 子孫拠点に入力されている数値を合算し、新しい小計とする
            const reTotalRow = descendantsSiteRows.reduce((acc, current) => {
                const level = getHierarchyLevel(sites, row.siteId as number)
                const currentLevel = getHierarchyLevel(sites, current.siteId as number)
                const accSubTotal = acc.items.find((item) => item.energySavingId === (-1 * level))?.value ?? 0
                const currentSubTotal = current.items.find((item) => item.energySavingId === (-1 * currentLevel))?.value ?? 0
                const subTotal = accSubTotal + currentSubTotal
                const subTotalItems = [
                    { energySavingId: -1, value: level === 1 ? subTotal : 0, inputtable: false },
                    { energySavingId: -2, value: level === 2 ? subTotal : 0, inputtable: false },
                    { energySavingId: -3, value: level === 3 ? subTotal : 0, inputtable: false },
                    { energySavingId: -4, value: level === 4 ? subTotal : 0, inputtable: false },
                    { energySavingId: -5, value: level === 5 ? subTotal : 0, inputtable: false },
                ]
                return {
                    ...acc,
                    items: [
                        ...subTotalItems,
                        ...acc.items.filter((_, index) => index >= subTotalItems.length)
                    ]
                }
            })
            return reTotalRow
        })
        // 合計行の数値を更新する
        return updateTotalRow(reCalculatedRows)
    }

    const updateTotalRow = (rows: EnergySavingRow[]) => {
        const matrixData = rows.map((r) => r.items.map((item) => item.value))
        const totalRowItemValues = sumOfMatrix(matrixData)
        // 合計行のsiteIdは、便宜上`-1`とする
        const allRows = [
            ...rows.filter((row) => row.siteId !== -1),
            {
                siteId: -1,
                title: '合計',
                level: 1,
                items: totalRowItemValues.map((value) => { return { value: value, inputtable: false } })
            }
        ]
        return allRows
    }

    const prepareEnergySavingCsv = () => {
        // FIXME: to be improved
        const { grandParent, parent, children } = require('../../../../dummy/energy_saving.json')
        const subHeader = genSubTotalUnits()
        const csvResult = initializeTableData(true)
            .map((td) => { return { siteId: td.siteId, name: td.title, data: td.items.map((item) => item.value) } })
            .filter((td) => td.siteId && td.siteId > 0)
        const matrixData = csvResult.map((r) => r.data)
        const units = subHeader.concat(tableColCSV).map((head) => {
            let unit = head.name.toString().replace(',', '')
            if (unit.includes('t-CO₂e')) {
                unit = unit.replace('t-CO₂e', 't-CO2e')
            }
            return unit
        })
        const data = csvResult.map((re) => {
            if (!re.siteId) {
                return [re.name, '-', '-', '-', '-', ...re.data]
            }
            const siteNames = getParentSites(sites, re.siteId).map((site) => site.name).reverse()
            return [
                siteNames[0] ?? '-',
                siteNames[1] ?? '-',
                siteNames[2] ?? '-',
                siteNames[3] ?? '-',
                siteNames[4] ?? '-',
                ...re.data
            ]
        })
        const total = sumOfMatrix(matrixData)
        const csvRowData = [
            grandParent,
            parent,
            children,
            ['-', '-', '-', '-', '-', ...units],
            ...data,
            ['合計', '-', '-', '-', '-', ...total]]
        const csvData = convertEnergySavingToCsv(csvRowData)
        props.setCsvData(csvData)
    }

    const handleClickSaveButton = async () => {
        if (!rows.length) {
            return
        }
        if (changedNothing(rows)) {
            return
        }

        const tmpRows = [...rows].filter((row) => row.siteId != -1)
        const arrs: EnergySavingOriginateCreateArr[][] = tmpRows.map((row) => {
            const itemMap = row.items.filter((item) => !!item.energySavingId && item.energySavingId > 0).reduce((map, item) => {
                const key = `${row.siteId}_${item.energySavingId}`
                const value: EnergySavingOriginateCreateArr = map.get(key) ?? {
                    energy_saving_id: item.energySavingId!!,
                    site_id: row.siteId!!,
                    quantity: 0,
                    number_emission: 0,
                    heat_quantity: 0,
                }
                if (item.valueType === 'activityQuantity') {
                    value.quantity = item.value
                }
                if (item.valueType === 'emissionNumber') {
                    value.number_emission = item.value
                }
                if (item.valueType === 'heatQuantity') {
                    value.heat_quantity = item.value
                }
                map.set(key, value)
                return map
            }, new Map<string, EnergySavingOriginateCreateArr>())
            const results: EnergySavingOriginateCreateArr[] = []
            itemMap.forEach((value, _) => { results.push(value) })
            return results
        })
        const flatedArrs = [...arrs.flat()]
        const params: EnergySavingOriginateCreate = {
            year: props.selectedYear,
            data: flatedArrs,
        }
        try {
            await energySavingReportApi.createEnergySavingOriginate(params)
            props.onReFetch()
            setMessage({ message: '入力値を保存しました。', type: 'success' })
        } catch (error: any) {
            console.error({ error })
            const message = checkMessageText(error?.status, error?.data?.errors?.[0]?.message)
            setMessage({ message, type: 'error' })
        }
    }

    const handleChangeRow = (row: EnergySavingRow) => {
        const index = rows?.findIndex((r) => r.siteId === row.siteId)
        if (!rows[index]) {
            return
        }
        const tmpRows = [...rows]
        tmpRows[index] = row
        const calculatedlRows = calculateSubTotals(tmpRows)
        setRows(calculatedlRows)
        prepareEnergySavingCsv()
        
        const disabled = changedNothing(calculatedlRows)
        setDisabledSaveButton(disabled)
    }

    const changedNothing = (targetRows: EnergySavingRow[]) => {
        return originalRows.every((originalRow) => {
            const targetRow = targetRows.find((row) => row.siteId === originalRow.siteId)
            if (!targetRow) {
                return false
            }
            return JSON.stringify(originalRow.items.filter((item) => item.energySavingId && item.energySavingId > 0)) === JSON.stringify(targetRow.items.filter((item) => item.energySavingId && item.energySavingId > 0))
        })
    }

    return (
        <>
            <div className={classes.btnSaveData}>
                <div>
                    <PrimaryButton
                        style={{ fontWeight: 400 }}
                        width={215}
                        height={38}
                        fontSize={15}
                        disabled={disabledSaveButton}
                        onClick={handleClickSaveButton}
                    >
                        入力したデータを保存する
                    </PrimaryButton>
                </div>
            </div>
            <div className={classes.container}>
                <div className="vertical-line" />
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((item, idx) => {
                                    return (
                                        <TableCell
                                            key={idx}
                                            style={{ minWidth: 216 }}
                                            colSpan={item?.units?.length || 1}
                                            rowSpan={idx === 0 ? 4 : idx === 1 ? 3 : 1}
                                            className={`${classes.cell} ${classes.headerCell} ${
                                                !idx ? classes.stickyCol : ''
                                            }`}
                                            align="center"
                                        >
                                            {item.name}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((item) => {
                                    if (!item?.children?.length) return null
                                    return item?.children?.map((inner, idx) => {
                                        return (
                                            <TableCell
                                                key={idx}
                                                colSpan={inner?.children ? inner?.children?.length * 3 : 3}
                                                rowSpan={inner?.children?.length ? 1 : 2}
                                                className={`${classes.cell} ${classes.headerCell}`}
                                                align="center"
                                            >
                                                {inner.name}
                                            </TableCell>
                                        )
                                    })
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((item) => {
                                    return item?.children?.map((inner) => {
                                        return inner?.children?.map((inner2, idx) => {
                                            return (
                                                <TableCell
                                                    key={idx}
                                                    colSpan={inner2.children ? inner2.children?.length * 3 : 3}
                                                    className={`${classes.cell} ${classes.headerCell}`}
                                                    align="center"
                                                >
                                                    {inner2.name}
                                                </TableCell>
                                            )
                                        })
                                    })
                                })}
                            </TableRow>
                            <TableRow>
                                {tableHeader.map((h) => {
                                    if (!h?.units) return
                                    return h?.units?.map((unit, idx) => {
                                        return (
                                            <TableCell
                                                key={idx}
                                                className={`${classes.cell} ${classes.headerCell} ${classes.unitCell}`}
                                                align="center"
                                            >
                                                {unit.name.map((name, idx) => (
                                                    <span
                                                        key={idx}
                                                        style={{ fontSize: idx ? 12 : 14, display: 'inline-block' }}
                                                    >
                                                        {name}
                                                    </span>
                                                ))}
                                            </TableCell>
                                        )
                                    })
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length > 1 ? (
                                rows.map((site, idx) => {
                                    return (
                                        <EnergySavingTableRow
                                            key={`energysaving-table-row-${site.siteId}-${idx}`}
                                            row={site}
                                            onChangeRow={handleChangeRow}
                                        />
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={tableCol.length + 7} align="center" className={``}>
                                        <NoRowsOverlay />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="desc">※各項目の数値は端数処理をしているため、小計と合計が一致しない場合があります。</div>
            </div>
        </>
    )
}

export default EnergySavingTable
