import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import InputField from '@/components/atoms/InputField'
import theme from '@/theme'
import { thousandSeparator } from '@/utils/numHelper'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EnergySavingRow } from '../EnergySavingTable'

interface EnergySavingTableRowProps {
    row: EnergySavingRow
    onChangeRow: (row: EnergySavingRow) => void
}

const ErrorText = styled.div`
    color: ${theme.colors.error};
    margin-top: 2px;
    font-size: 12px;
    width: max-content;
    float: right;
`

const useStyle = makeStyles({
    tableCell: {
        padding: '8px 30px 8px 16px',
        color: theme.colors.black2,
        fontSize: 14,
    },
    firstCol: {
        fontSize: 14,
        fontWeight: 300,
        backgroundColor: '#FDF3DF',
        borderTop: `4px solid #fff`,
        borderRight: `4px solid #fff`,
    },
    totalCol: {
        backgroundColor: '#F2F2F2',
        borderRight: `4px solid #fff`,
    },
    firsttotalCol: {
        borderTop: `4px solid #fff`,
    },
    other: {
        backgroundColor: '#F5DBAE',
        padding: '12px 16px 12px 16px',
    },
    inputCol: {
        padding: '8px 0px 8px 16px',
    },
    inputColInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },
    inputErrorIcon: {
        width: 14,
        marginTop: 5
    },
    input: {
        width: 135,
        marginLeft: 7,
        '& fieldset': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
        },
        '& input': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
            textAlign: 'right',
        },
    },
    stickyCol: {
        position: 'sticky',
        left: 1,
    },
    cell: {
        minWidth: 100,
        fontSize: 14,
        padding: 9
    },
    headerCell: {
        fontWeight: 600,
        color: '#fff',
        backgroundColor: '#6E6E6E',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    unitCell: {
        backgroundColor: '#AAAAAA',
    },
    bodyCell: {
        color: '#222222',
        fontWeight: 300,
    },
    bodySiteCell: {
        backgroundColor: '#FDF3DF',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    bodyTotalCell: {
        fontWeight: 700,
        backgroundColor: '#F5DBAE',
    },
})

function EnergySavingTableRow(props: EnergySavingTableRowProps) {
    const { row, onChangeRow } = props
    const [values, setValues] = useState<Map<number, number>>(new Map())
    const [errors, setErrors] = useState<Map<number, string>>(new Map())
    const classes = useStyle()

    useEffect(() => {
        const tmpValues = new Map(
            row.items
                .map((item, index) => {
                    return [index, item.value]
                })
        )
        setValues(tmpValues)
    }, [row])

    const updateValues = (columnIndex: number, value: number) => {
        const tmpValues = new Map(values)
        tmpValues.set(columnIndex, value)
        setValues(tmpValues)
    }

    const updateErrors = (columnIndex: number, errorMessage: string) => {
        const tmpErrors = new Map(errors)
        tmpErrors.set(columnIndex, errorMessage)
        setErrors(tmpErrors)
    }

    const clearErrors = (columnIndex: number) => {
        updateErrors(columnIndex, '')
    }

    const handleInputChange = (columnIndex: number, value: string) => {
        const ZENKAKU_NUMBERS = /[\uFF10-\uFF19]/

        if (ZENKAKU_NUMBERS.test(value)) {
            updateErrors(columnIndex, '半角数字を入力してください')
            return
        }

        const newValue = Number(value)
        if (isNaN(newValue)) {
            updateErrors(columnIndex, '半角数字を入力してください')
            return
        }

        const [integerPart, decimalPart] = value.split('.')
        if (integerPart?.length > 12) {
            updateErrors(columnIndex, '最大12桁まで')
            return
        }
        if (decimalPart?.length > 3) {
            updateErrors(columnIndex, '小数点以下は3桁まで')
            return
        }

        if (value.charAt(0) === '0' && value.charAt(1) === '0') {
            updateValues(columnIndex, 0)
            return
        }

        clearErrors(columnIndex)

        const tmpItems = [...row.items]
        if (!tmpItems[columnIndex]?.inputtable) {
            return
        }
        tmpItems[columnIndex].value = newValue
        const tmpRowData = {
            ...row,
            items: tmpItems
        }
        onChangeRow(tmpRowData)
        updateValues(columnIndex, newValue)
    }

    const renderSpaces = (level?: number) => {
        if (!level) {
            return ''
        }
        const spaces = []
        let count = 1
        while (level > count) {
            spaces.push(String.fromCharCode(160), String.fromCharCode(160), String.fromCharCode(160))
            count++
        }
        return spaces.join('')
    }

    return (
        <TableRow key={row.siteId}>
            <TableCell
                className={`${classes.cell} ${classes.bodyCell} ${classes.bodySiteCell} ${row.siteId === - 1 ? classes.bodyTotalCell : ''
                    } ${classes.stickyCol}`}
            >
                {renderSpaces(row.level)}{row.title}
            </TableCell>
            {row.items.map((item, index) => {
                if (item.inputtable) {
                    return (
                        <TableCell
                            key={index}
                            className={classes.inputCol}
                            align="right"
                        >
                            <div className={classes.inputColInner}>
                                <span className={classes.inputErrorIcon}>{errors.get(index) && <ErrorIcon />}</span>
                                <InputField
                                    value={thousandSeparator(`${Math.round(values.get(index) ?? NaN)}`)}
                                    onChange={(e) => { return handleInputChange(index, e.target.value.split(',').join('')) }}
                                    error={!!errors.get(index)}
                                    className={classes.input}
                                />
                            </div>
                            {errors.get(index) && <ErrorText>{errors.get(index)}</ErrorText>}
                        </TableCell>
                    )
                }
                return (
                    <TableCell
                        key={index}
                        className={`${classes.cell} ${classes.bodyCell}`}
                        align={item.value ? 'right' : 'center'}
                    >
                        {item.value ? thousandSeparator(`${Math.round(item.value)}`) : '-'}
                    </TableCell>
                )
            })}
        </TableRow>
    )
}
export default React.memo(EnergySavingTableRow)
