import Space from '@/components/atoms/Space'
import SelectSitesModal from '@/components/molecules/Modals/SelectSitesModal'
import ReportingYearSelector from '@/components/molecules/SelectorGroup/reportingYearSelect'
import { withLayout } from '@/components/template/Layout'
import EnergySavingTable from '@/components/template/Reporting/EnergySaving/EnergySavingTable'
import { YEAR_OPTIONS } from '@/constant/options'
import { KEY_LOCALSTORAGE } from '@/constant/text'
import { energySavingLevelApi, energySavingReportApi, siteNodeApi } from '@/ghgApi'
import {
    AnnualOrganizationSummary,
    EnergySavingLevel,
    EnergySavingReport,
    HalfYearOrganizationSummary,
    MonthlyOrganizationSummary,
    QuarterlyOrganizationSummary,
    SiteNode,
} from '@/openapi/api'
import caches from '@/utils/cache'
import { getTime } from '@/utils/nendo'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'

export type CompareResponseData = AnnualOrganizationSummary | HalfYearOrganizationSummary | QuarterlyOrganizationSummary

export type ResponseData = CompareResponseData | MonthlyOrganizationSummary
export type TEnergySavingTable = {
    headers: EnergySavingLevel[]
    data: EnergySavingReport[]
    filteredData: EnergySavingReport[]
}

// ====================================================================================
// Overview
// ====================================================================================

const useStyle = makeStyles({
    tableWrapper: {
        marginTop: 80,
    },
})

const OverviewInner = () => {
    const { expandedMenu, storeState } = useStore()
    const [csvData, setCsvData] = useState<Blob>()
    const [_, setYearSelectorHeight] = useState<number>(0)
    const [yearSelect, setYearSelect] = useState<number>(YEAR_OPTIONS?.[0]?.id || getTime().year)
    const [dataTable, setDataTable] = useState<TEnergySavingTable>({ headers: [], data: [], filteredData: [] })
    const [showTable, setShowTable] = useState(false)
    const [open, setOpen] = useState(false)
    const [listSitesSelect, setListSitesSelect] = useState<number[]>(
        caches.getJson(KEY_LOCALSTORAGE.energy_saving) || [],
    )
    const [siteNodes, setSiteNodes] = useState<SiteNode[]>([])
    const classes = useStyle()

    const stylesFixed = {
        width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
        transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }

    useEffect(() => {
        fetchSiteNodes()
    }, [storeState.organization])

    const fetchSiteNodes = async () => {
        if (!storeState.organization) {
            return
        }
        try {
            const res = await siteNodeApi.getSiteNodes(storeState.organization.id)
            const { siteNodes } = res.data
            if (siteNodes) {
                setSiteNodes(siteNodes)
            }
        } catch (err) {
            console.warn(err)
        }
    }


    const handleOpen = () => {
        setOpen(true)
    }
    const handleCsvCick = () => {
        if (csvData) FileSaver.saveAs(csvData, `reporting-energy-saving-data-table.csv`)
        else console.warn('no data to download')
    }

    function onYearSelected(year: number) {
        setYearSelect(year)
    }

    // fetch data
    const fetchData = async () => {
        try {
            const newDataTable: TEnergySavingTable = { ...dataTable }
            if (!newDataTable.headers.length) {
                const { data: responseHeader } = await energySavingLevelApi.getEnergySavingLevel()
                newDataTable.headers = responseHeader
            }
            const { data: responseBody } = await energySavingReportApi.getEnergySavingReport(undefined, yearSelect)
            newDataTable.data = responseBody
            if (listSitesSelect.length) {
                const filteredData: EnergySavingReport[] = []
                listSitesSelect.forEach((id) => {
                    const site = responseBody.find((site) => site.site_id === id)
                    if (site) {
                        filteredData.push(site)
                    }
                })
                newDataTable.filteredData = filteredData
            } else {
                newDataTable.filteredData = newDataTable.data
            }
            setDataTable(newDataTable)
            setShowTable(true)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (yearSelect) fetchData()
    }, [yearSelect, listSitesSelect])

    const handleReFetch = () => {
        fetchData()
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <main>
            <title>レポーティング（省エネ法）｜Emission View</title>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    top: 70,
                    ...stylesFixed,
                }}
            >
                <Space />
                <ReportingYearSelector
                    onCsvClick={handleCsvCick}
                    setYearSelectorHeight={setYearSelectorHeight}
                    onYearSelected={onYearSelected}
                    year={yearSelect}
                    handleOpen={handleOpen}
                />
                <Space />
            </div>
            <div className={classes.tableWrapper}>
                {showTable ? <EnergySavingTable data={dataTable} setCsvData={setCsvData} selectedYear={yearSelect} onReFetch={handleReFetch} /> : null}

                {open && (
                    <SelectSitesModal
                        siteNodes={siteNodes}
                        open={open}
                        listSitesSelect={listSitesSelect}
                        keyLocalStorage={KEY_LOCALSTORAGE.energy_saving}
                        setListSitesSelect={setListSitesSelect}
                        handleClose={handleClose}
                        setDataTable={setDataTable}
                    />
                )}
            </div>
        </main>
    )
}

const Overview = () => <OverviewInner />

export default withLayout(Overview)
